import React, { Fragment } from 'react';
import { Button, Form, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Modal from '../../screens/auth/login';
import ModalSignUp from '../../screens/auth/signup';
import ModalResetPassword from '../../screens/auth/resetPassword';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { logout } from '../../helpers/query.helper';
import Notifications, { notify } from 'react-notify-toast';
import { useHistory } from 'react-router';
import * as auth from '../../services/auth.service';
import { loader } from '../../redux/slices/app.slice';
import { STATUS_REQUEST_SUCCESS } from '../../services/common.service';
import { Img } from '../common/Img';
import './header.css';
import styled from 'styled-components';

function Header() {
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowSignUp, setModalShowSignUp] = React.useState(false);
    const [ResetPassword, setModalShowResetPassword] = React.useState(false);
    const user: any = useSelector<RootState>((state) => state.user.user);
    const history = useHistory();

    function logoutUser() {
        logout(user);
        auth.Logout('', loader).then((res) => {
            if (res.status === STATUS_REQUEST_SUCCESS) {
                if (res.response_status) {
                    history.push('/');
                } else {
                    for (let i = 0; i < res.error.length; i++) {
                        notify.show(res.error[i]['msg'], 'error', 3000);
                    }
                }
            } else {
                notify.show('Connection Error', 'error', 3000);
            }
        });
    }

    return (
        <div className={'nav-area'}>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                switchToSignup={() => setModalShowSignUp(true)}
                swtichtoResetPassword={() => setModalShowResetPassword(true)}
                onSuccess={() => notify.show('Logged in!', 'success', 3000)}
            />
            <ModalSignUp
                show={modalShowSignUp}
                onHide={() => setModalShowSignUp(false)}
                switchToLogin={() => setModalShow(true)}
                onSuccess={() =>
                    notify.show('Successfully signed up!', 'success', 3000)
                }
            />
            <ModalResetPassword
                show={ResetPassword}
                onHide={() => setModalShowResetPassword(false)}
                onSuccess={() =>
                    notify.show('Password link sent!', 'success', 3000)
                }
            />
            <Notifications />
            <Navbar bg="dark" expand="lg">
                <a href="/">
                    <Img alt="StoxPathshala" src="logo_1x.png" width="60px" />
                </a>
                <a href="/">
                    <PageTitle>StoxPathshala</PageTitle>
                </a>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link
                            href="https://www.youtube.com/channel/UCEVPan2l-XmjL8nEYg779Ig"
                            target="_blank"
                        >
                            Youtube
                        </Nav.Link>
                        <Nav.Link href="/trend-trading">
                            Trend Trading Calculator
                        </Nav.Link>
                        <NavDropdown title="Services" id="product-nav-dropdown">
                            {/*<NavDropdown.Item href="/bestdeliverystocks">*/}
                             {/*   Best Stock Delivery*/}
                            {/*</NavDropdown.Item>*/}
                            {/*<NavDropdown.Item href="/stockobservation">*/}
                            {/*    Stock Observation Service*/}
                            {/*</NavDropdown.Item>*/}
                            <NavDropdown.Item href="/master_class">
                                Master Class
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="/opentradingaccount">
                            Open D-Mat & Trading Account
                        </Nav.Link>
                        {user ? (
                            <Button
                                variant="outline-light"
                                style={{ width: '120px' }}
                                onClick={() => history.push('/pay')}
                            >
                                Pay Now
                            </Button>
                        ) : (
                            <Button
                                variant="outline-light"
                                style={{ width: '120px' }}
                                onClick={() => setModalShow(true)}
                            >
                                Pay Now
                            </Button>
                        )}
                    </Nav>
                    <Form inline>
                        {user ? (
                            <Fragment>
                                <Button
                                    variant="primary"
                                    style={{
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                        marginTop: '5px',
                                        borderTopLeftRadius: '20px',
                                        borderBottomLeftRadius: '20px',
                                    }}
                                    onClick={() => history.push('/profile')}
                                >
                                    Profile
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => logoutUser()}
                                    style={{
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                        marginTop: '5px',
                                        borderTopRightRadius: '20px',
                                        borderBottomRightRadius: '20px',
                                    }}
                                >
                                    Logout
                                </Button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Button
                                    variant="primary"
                                    onClick={() => setModalShow(true)}
                                    style={{
                                        marginRight: '5px',
                                        borderTopLeftRadius: '20px',
                                        borderBottomLeftRadius: '20px',
                                    }}
                                >
                                    Login
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => setModalShowSignUp(true)}
                                    style={{
                                        borderTopRightRadius: '20px',
                                        borderBottomRightRadius: '20px',
                                    }}
                                >
                                    SignUp
                                </Button>
                            </Fragment>
                        )}
                    </Form>
                </Navbar.Collapse>
            </Navbar>
            {/*<Profile/>*/}
        </div>
    );
}
const PageTitle = styled.div`
    font-size: 20px;
    color: white;
    font-weight: bold;
    margin: 0 10px 0 10px;
`;
export default Header;
