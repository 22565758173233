import React, {useState} from 'react';
import {Carousel} from 'react-bootstrap';
import {Img} from '../common/Img';
import {useHistory} from "react-router-dom";

function CarouselHome() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex: any) => {
    setIndex(selectedIndex);
  };
  const history = useHistory();
  return (<Carousel activeIndex={index} onSelect={handleSelect} className='carousel-center'>
      <Carousel.Item>
        <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
          <Img
            className={'carousel-image'}
            alt="Trade daily"
            src="/home/DelhiClass.jpeg"
            width="100%"
          />
        </div>
      </Carousel.Item>
    <Carousel.Item>
        <div style={{display: 'flex', width: '100%', justifyContent: 'center'}} onClick={() => history.push('/pay')}>
          <Img
            className={'carousel-image'}
            alt="Trade daily"
            src="/home/trading_room.png"
            width="100%"
          />
        </div>
      </Carousel.Item>
      {/*<Carousel.Item>*/}
      {/*  <div style={{display: 'flex', width: '100%', justifyContent: 'center'}} onClick={() => history.push('/pay')}>*/}
      {/*    <Img*/}
      {/*      className={'carousel-image'}*/}
      {/*      alt="Trade daily"*/}
      {/*      src="/home/trade-daily.jpg"*/}
      {/*      // width="100%"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</Carousel.Item>*/}
      <Carousel.Item>
        <Img
          alt="trend trading calculator"
          src="/home/calculator.png"
          width="100%"
        />
      </Carousel.Item>
      <Carousel.Item>
        <Img
          alt="Start Trading Today"
          src="/home/startTrading.jpg"
          width="100%"
        />
      </Carousel.Item>
      <Carousel.Item>
        <Img
          alt="Start Learning Today"
          src="/home/startLearning.jpg"
          width="100%"
        />
      </Carousel.Item>
    </Carousel>);
}

export default CarouselHome;
